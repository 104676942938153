import consumer_trains_order_getTrainsChangerOrderList from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsChangerOrderList'
import consumer_trains_order_cancelChangeOrder from '@/lib/data-service/haolv-default/consumer_trains_order_cancelChangeOrder'
import Pagination from '@/component/pagination/index.vue'
export default {
    data () {
        return {
            loading: false,
            params: {
                currentPage: 1,
                pageSize: 10,
                orderStatus: '', // 订单状态
                orderNo: '', // 订单编号
                scheduledWay: '', // 预定方式
                passengerName: '', // 乘客姓名
                total: 0
            },
            orderStatusList: [ // 1-申请退款 2-退款中 3-退票成功 4-退票失败 5- 取消退票
                {value: '', label: '全部'},
                {value: 0, label: '申请改签'},
                {value: 1, label: '待支付'},
                {value: 2, label: '改签中'},
                {value: 3, label: '改签成功'},
                {value: 4, label: '改签失败'},
                {value: 5, label: '已取消'}
            ],
            scheduledWayList: [
                {value: '', label: '全部'},
                {value: 2, label: '因公出差'},
                {value: 1, label: '因私出行'}
            ],
            orderList: [],
            reloadTicketFun: '',
            reloadNum: 0,
            showLoading: true
        }
    },
    components: {
        Pagination
    },
    created () {},
    mounted () {

    },
    activated () {
        this.getChangeOrderList()
        this.reloadList()
    },
    deactivated() {
        clearInterval(this.reloadTicketFun)
    },
    destroyed () {
        clearInterval(this.reloadTicketFun)
    },
    watch: {},
    computed: {},
    filters: {
        filterWhatDay(val) {
            if (val === 1) {
                return '周一'
            } else if (val === 2) {
                return '周二'
            } else if (val === 3) {
                return '周三'
            } else if (val === 4) {
                return '周四'
            } else if (val === 5) {
                return '周五'
            } else if (val === 6) {
                return '周六'
            } else if (val === 7) {
                return '周日'
            } else {
                return ''
            }
        }
    },
    methods: {
        search() {
            this.params.currentPage = 1
            this.getChangeOrderList()
        },

        //重置筛选条件
        onReset() {
            this.params = {
                currentPage: 1,
                pageSize: 10,
                orderStatus: 0, // 订单状态
                orderNo: '', // 订单编号
                scheduledWay: '', // 出行类型
                passengerName: '', // 乘客姓名
                total: 0
            }
            this.getChangeOrderList();
        },

        getChangeOrderList() {
            if (this.showLoading) {
                this.loading = true
            }
            consumer_trains_order_getTrainsChangerOrderList(this.params).then(res => {
                this.loading = false
                this.showLoading = true
                this.orderList = res.datas.list
                this.params.total = res.datas.totalCount
            }).catch(() => {
                this.loading = false
                this.showLoading = true
                this.orderList = []
                this.params.total = 0
            })
        },
        goDetail(val) {
            this.$router.push({
                name: 'admin-my-order-train-change-detail',
                query: {
                    orderNo: val.orderNo,
                    changeNo: val.changeNo,
                    jumpType: 'list'
                }
            })
        },
        cancelOrder(val) {
            this.$confirm('此操作将取消该订单的改签, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                consumer_trains_order_cancelChangeOrder({changeNo: val.changeNo}).then(res => {
                    loading.close()
                    let ifIsSuccessful = res.datas.ifIsSuccessful
                    if (ifIsSuccessful === 1) {
                        this.$message({
                            type: 'success',
                            message: '取消成功'
                        })
                        this.getChangeOrderList()
                    } else {
                        this.$message({
                            type: 'warning',
                            message: '取消改签失败，请稍后再试'
                        })
                    }
                }).catch(() => {
                    loading.close()
                })
            }).catch(() => {

            })
        },
        goPay(val) {
            this.$router.push({
                name: 'admin-train-change-submit-pay',
                query: {
                    orderNo: val.orderNo,
                    changeNo: val.changeNo,
                }
            })
        },
        reloadList() {
            this.reloadTicketFun = setInterval(() => {
                this.reloadNum++
                if (this.reloadNum === 60) {
                    this.showLoading = false
                    this.getChangeOrderList()
                    // 页面停留超过10分钟，正在为您重新查询
                    this.reloadNum = 0
                }
            }, 1000)
        },

        //订单状态的样式
        // 改签单状态 0.改签申请中 1.待补款(待支付) 2.改签中 3.改签成功 4.改签失败 5.已取消 6.未改签 待审批 7.待确认
        returnStatus(item) {
            if (item.orderStatus === 3) {
                return 'status2' // 绿色
            } else if (item.orderStatus === 4) {
                return 'status3' // 红色
            } else if (item.orderStatus === 5) {
                return 'status4' // 灰色
            } else {
                return 'status1' // 黄色
            }
        },
    }
}
